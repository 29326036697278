<template>
    <div class="map">
         <h5 class="post-heading">Wo ist das?</h5>
        <single-map v-if="content !== null && addressObj !== null " class="map-container" :content="content" :addressObj="addressObj" style="height:586px;"></single-map>
    </div>


</template>

<script>

    export default {
        name: 'DetailMap',
        components: {
            SingleMap: () => import('@/components/map/single.vue'),
        },
        props: {
          height: {
            type: String,
            default: '700px',
        },
        content: {
            type: Object,
            required: true,
            default: {},
        },
        addressObj: {
            type: Object,
            default: {},
        },
    },
}
</script>

<style lang="scss" scoped>

    .map {

        @media (max-width: 991px) {
            display: none;
        }
    }

</style>
